/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import { Heading, HeadingProps } from '@schibsted-smb/fireball';
import Trans from 'next-translate/Trans';

import { Span } from '~components/base/Span';

interface TitleProps extends HeadingProps {
  spanColor?: string;
  spanTitle?: string;
}

const H1: React.FC<React.PropsWithChildren<Omit<TitleProps, 'spanColor' | 'spanTitle'>>> = ({
  children,
  fontSize,
  fontWeight,
  textAlign,
  mb,
  width,
  ...rest
}) => (
  <Heading.h1
    fontWeight={fontWeight || 'bold'}
    letterSpacing="-0.02em"
    fontSize={fontSize || ['32px', null, null, '40px', '48px', '56px', '64px']}
    textAlign={textAlign || null}
    lineHeight="110%"
    width={width || null}
    color="black.black9"
    mb={mb || null}
    {...rest}
  >
    {children}
  </Heading.h1>
);

const H2: React.FC<React.PropsWithChildren<TitleProps>> = ({
  children,
  fontSize,
  textAlign,
  mb,
  my,
  px,
  width,
  spanColor,
  spanTitle,
  ...rest
}) => (
  <Heading.h2
    fontSize={fontSize || ['24px', null, null, null, '32px', null, null, null, '40px', '48px']}
    fontWeight="bold"
    lineHeight="115%"
    textAlign={textAlign || null}
    letterSpacing="-0.02em"
    color="black.black9"
    mb={mb || null}
    my={my || null}
    px={px || null}
    width={width || null}
    {...rest}
  >
    {spanColor ? (
      // eslint-disable-next-line react/jsx-key
      <Trans i18nKey={spanTitle} components={[<Span color={spanColor} />]} />
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    )}
  </Heading.h2>
);

const H3: React.FC<React.PropsWithChildren<Omit<TitleProps, 'spanColor' | 'spanTitle'>>> = ({
  children,
  fontSize,
  mb,
  px,
  width,
  ...rest
}) => (
  <Heading.h3
    fontSize={fontSize || ['14px', null, null, null, null, '16px']}
    fontWeight="bold"
    lineHeight="115%"
    width={width || null}
    letterSpacing="-0.02em"
    color="black.black9"
    mb={mb || null}
    px={px || null}
    {...rest}
  >
    {children}
  </Heading.h3>
);

const H4: React.FC<React.PropsWithChildren<Omit<TitleProps, 'spanColor' | 'spanTitle'>>> = ({
  children,
  fontSize,
  mb,
  ...rest
}) => (
  <Heading.h4
    fontSize={fontSize || ['24px', null, null, null, '32px', null, null, null, '40px', '48px']}
    fontWeight="bold"
    lineHeight="115%"
    letterSpacing="-0.02em"
    color="black.black9"
    mb={mb || null}
    {...rest}
  >
    {children}
  </Heading.h4>
);

export const Title = {
  H1,
  H2,
  H3,
  H4,
};
